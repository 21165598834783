import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { images } from "../../constants";
import "./Home.css";

const headTextTicks = [
  "Connect with people around you effortlessly",
  "Filter by interests for meaningful conversations",
  "Start chatting instantly, no small talk required",
];

const Home = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hallOfFame, setHallOfFame] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 32);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchHallOfFame = async () => {
      try {
        const response = await fetch("https://ofc-newsletter.onrender.com/api/names");
        if (response.ok) {
          const data = await response.json();
          console.log("Got data:", data);
          setHallOfFame(data); // Update the state with the fetched data
        } else {
          console.error("Failed to fetch names");
        }
      } catch (error) {
        console.error("Error fetching names:", error);
      }
    };
  
    // Fetch names from the API every time the page refreshes
    fetchHallOfFame();
  }, []);  // The empty dependency array ensures it only runs once per page load
  

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Prepare the data to be sent
    const data = {
      data: [{ Name: name, Email: email }], // Adjust according to your SheetDB structure
    };

    try {
      const response = await fetch("https://sheetdb.io/api/v1/1oer5bacj9neh", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        setMessage("Subscribed successfully!");
        // Optionally reset the form
        setName("");
        setEmail("");
      } else {
        throw new Error("Failed to subscribe");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("There was an error subscribing. Please try again.");
    }
  };

  return (
    <div className="app__home">
      <div className="app__home-hero" id="home">
        <div
          className="app__home-hero-nav"
          style={
            hasScrolled
              ? {
                  backgroundColor: "var(--secondary-red-transparent)",
                  backdropFilter: "blur(8px)",
                  padding: "0.75rem",
                }
              : {}
          }
        >
          <a href="#about">About</a>
          <a href="#home">
            <img src={images.logo} alt="logo" height="70"/>
          </a>
          <a>Download</a>
        </div>
        <div className="app__home-hero-presentation">
          <div className="app__home-hero-text">
            <h1>Open For Conversation</h1>
            <div className="app__home-hero-list-container">
              {headTextTicks.map((value, index) => (
                <motion.div
                  className="app__home-hero-list-item"
                  key={index}
                  whileInView={{ x: [100, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  <img src={images.tickLight} alt="tick" height="15" />
                  <p>{value}</p>
                </motion.div>
              ))}
            </div>
            <div className="app__home-hero-button">
              <a href="#subscribe">Join Us</a>
            </div>
          </div>
          <div className="app__home-hero-img">
            <img src={images.concept3} alt="OpenForConversation-logo" />
          </div>
        </div>
      </div>

      <div className="app__home-body-left" id="about">
        {/*<div className="app__home-body-left-img">*/}
        {/*  <div id="left-right-img">*/}
        {/*    <div id="label">*/}
        {/*      <img height="30" src={images.conversationIncon} alt="icon" />*/}
        {/*      <p style={{ marginLeft: "0.5rem" }}>Let's meet!</p>*/}
        {/*    </div>*/}
        {/*    <img src={images.concept1} alt="app-pages" />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <motion.div
          whileInView={{ x: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
          className="app__home-body-left-text"
        >
          <h1>Let's make this a reality!</h1>
          <p>
            <span style={{ fontWeight: 700 }}>Open For Conversation</span> is
            your go-to app for sparking real-life connections in a digital
            world. Say goodbye to superficial online interactions and hello to
            spontaneous meetups with like-minded people nearby!
          </p>
          <div className="app__home-body-button">
            <a
              href="https://forms.gle/R3iWjC4E7DDXuQnV9"
              target="_blank"
              rel="noreferrer"
            >
              Give us feedback!
            </a>
          </div>
        </motion.div>
      </div>

      <div className="subscribe-form" id="subscribe">
        <form onSubmit={handleSubmit}>
          <h2>I want in!</h2>
          <input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Join Us!</button>
        </form>
      </div>

      <div className="app__home-body-right">
        <motion.div
          whileInView={{ x: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
          className="app__home-body-right-text"
        >
          <h1>Hall of Fame</h1>
          <p>
            Subscribe to our newsletter to see your name on the hall of fame!
          </p>
          <br />
          <div className="app__home-body-right-text-table">
            {hallOfFame.map((value, index) => (
              <p key={index}>{value}</p>
            ))}
          </div>
        </motion.div>
        <div className="app__home-body-right-img">
          <div id="left-right-img">
            <img src={images.concept2} alt="app-pages" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
