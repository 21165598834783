import React from "react";
import { images } from "../../constants";

import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="app__footer">
        <div className="app__footer-copyright">
          © 2024 Open For Conversation. All Rights Reserved
        </div>
        <div className="app__footer-links">
          <div className="app__footer-link">
            <img src={images.email} height="20" alt="linkedin-icon" />
            <a href="mailto:openforconversationapp@gmail.com" rel="noreferrer">
              Email us!
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
